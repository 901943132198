#builder > div .tab {
  padding: 0 0 16px;
  border-bottom: solid 2px #000;
}

#builder > div .tab:has(+ .content .builderGuide) {
  border-bottom: none;
}
#builder > div .tab #pinkBtn2 {
  width: 150px;
  height: 41px;
  border-radius: 24px;
  font-size: 14px;
  margin: 0 2px;
  line-height: 1;
}

#builder > div .tab #pinkBtn2.active {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  border: 1px solid transparent;
}

#builder div .content {
  padding: 0;
  align-items: flex-start;
  min-height: 620px;
}

#builder div .content .layout {
  width: 60%;
}

#builder div .content .layout .selectWrapper {
  margin: 0 0 16px;
  display: flex;
  gap: 8px;
}

#builder div .content .layout .selectWrapper .ant-select {
  width: 120px;
  height: 32px;
  margin: 0;
}

#builder div .content .layout .ant-layout {
  width: 100%;
}

#builder div .content .layout .ant-layout .menuBox {
  min-width: 900px;
  overflow-x: auto;
}

#builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item {
  min-width: 110px;
  padding: 0 2px;
  height: 48px;
}
#builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected + li span {
  border-bottom-left-radius: 16px;
}

#builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item:has(+ .ant-menu-item-selected) span {
  border-bottom-right-radius: 16px;
}
/* #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item + li span {
  border-bottom-left-radius: 16px;
} */

#builder div .content .layout .ant-layout .ant-menu.headerMenu .ant-menu-title-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #525162;
  border-radius: 16px 16px 0 0;
  font-size: 14px;
  height: 48px;
  transition: all 0.2s ease-in-out;
  border-top: 4px solid #525162;
  border-bottom: 4px solid #525162;
  font-weight: 400;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom: none;
  border-bottom-color: transparent;
}
#builder div .content .layout .ant-layout .ant-menu.headerMenu .ant-menu-title-content:hover,
#builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected .ant-menu-title-content {
  background: #ffffff;
  color: #323146;
  height: 48px;
  font-weight: 600;
  border-top-color: #b041f3;
  border-bottom-color: #fff;
  box-shadow: 0px 0 2px rgba(0, 0, 0, 0.1);
}

.ant-breadcrumb li:last-child,
.ant-breadcrumb li:last-child {
  color: #ffffff;
}

#builder div .content .layout .ant-layout .inputBox {
  padding: 0 0 16px;
  /* border-bottom: solid 1px rgba(0, 0, 0, 0.1); */
}

#builder div .content .layout .ant-layout .inputBox:nth-child(3) {
  border-bottom: none;
}

#builder div .content .layout .ant-layout div h1 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 6.5px;
}

#builder div .content .layout .ant-layout div div .input {
  width: calc(100% - 77px - 16px);
  height: 40px;
  background: #efecf1;
  box-shadow: none;
  border-radius: 4px;
}

#builder div .content .layout .ant-layout div div .input::placeholder {
  color: #a3a1a7;
}

#builder div .content .layout .ant-layout div div .input:hover {
  border: 1px solid #b041f3;
}

#builder div .content .layout .ant-layout div div .input:focus {
  border: 1px solid #b041f3;
  background: #ffffff;
}

#builder div .content .layout .ant-layout div div #darkGrey2 {
  width: 77px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

/* 메뉴 디자인 */
.ant-menu-submenu-title {
  background: #f3f3f3;
  border: 1px solid #dbdbe0;
}

/* 메뉴호버시 */
.ant-menu .ant-menu-submenu-inline:hover .ant-menu-submenu-title {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
}

.ant-menu .ant-menu-submenu-inline:hover .ant-menu-submenu-title > .ant-menu-title-content {
  color: #ffffff;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  border: none;
}

/* 서브메뉴 배경색 */
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #ffffff;
}

/* 서브메뉴 선택시 디자인 */
.ant-menu-light .ant-menu-item-selected {
  color: #c718e2;
}

.ant-menu-light .ant-menu-item-selected {
  background: #fdf2ff;
}

/* 서브메뉴 화살표 */
.ant-menu-submenu-arrow {
  display: none;
}

/* 서브메뉴 호버했을때 */
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background: #fdf2ff;
  color: #c718e2;
}

#builder div .content .layout .ant-layout .stepTitle {
  margin: 0 0 8px;
}

#builder div .content .layout .ant-layout .stepTitle > p {
  font-size: 16px;
  margin: 0 10px 0 0;
  color: #c718e2;
}

#builder div .content .layout .ant-layout .stepTitle > h2 {
  font-size: 16px;
  font-weight: bold;
  color: #323146;
}

#builder div .content .layout .ant-layout .top {
  margin: 0 0 8px;
}

#builder div .content .layout .ant-layout .top p {
  width: 80px;
  font-size: 14px;
  color: #0e0e1f;
  margin: 0 17px 0 0;
}

#builder div .content .layout .ant-layout .top .line {
  background: #0e0e1f;
  height: 1px;
  width: calc(100% - 50px - 17px);
}

#builder div .content .layout .ant-layout .listBox {
  min-height: 500px;
  overflow: auto;
  flex-wrap: wrap;
}
#builder div .content .layout .ant-layout .listBox:has(p) {
  justify-content: center;
}
#builder div .content .layout .ant-layout .listBox p {
  min-height: 500px;
}
#builder div .content .layout .ant-layout .listBox .list#active,
#builder div .content .layout .ant-layout .listBox .list:hover {
  .thumBorder {
    display: block !important;
  }
}

#builder div .content .layout .ant-layout .listBox .list#active .absoluteBox,
#builder div .content .layout .ant-layout .listBox .list:hover .absoluteBox {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  color: #ffffff;
}
#builder div .content .layout .ant-layout .listBox .list .imgThum {
  width: 100%;
  height: 87px;
  background-color: #f7f7f8;
}

#builder div .content .layout .ant-layout .listBox .list#active .imgThum,
#builder div .content .layout .ant-layout .listBox .list:hover .imgThum {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: calc(100% - 8px);
  height: 80px;
  border-radius: 4px 4px 0 0;
}

#builder div .content .layout .ant-layout .listBox .list {
  width: calc(100% / 4 - 15px);
  margin: 0 19px 28px 0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 122px;
  position: relative;
}

#builder div .content .layout .ant-layout .listBox .list:nth-child(4n) {
  margin: 0 0 28px;
}

#builder div .content .layout .ant-layout .listBox .list img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

#builder div .content .layout .ant-layout .listBox .list .absoluteBox {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  background: #e6e6e6;
  /*font-size: 13px;
  font-weight: 600;
  color: #0e0e1f;
  max-height: 25px;
  white-space: nowrap; */

  display: flex;
  height: 40px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
#builder div .content .layout .ant-layout .listBox .list .absoluteBox span {
}
#builder .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  width: 140px;
}
#builder div .content .layout .ant-layout div div .input {
  width: calc(100% - 77px - 16px);
  height: 40px;
  background: #efecf1;
  box-shadow: none;
  border-radius: 4px;
}

#builder div .content .layout .ant-layout .textAreaBox .input {
  width: 100%;
}
#builder div .content .layout .ant-layout .promptInputBox {
  margin: 24px 0 0;
}

#builder .ant-select-outlined:not(.ant-select-customize-input).w200 .ant-select-selector {
  width: 100%;
}

#builder div .content .layout .ant-layout .promptInputBox .input::placeholder {
  color: #a3a1a7;
}

#builder div .content .layout .ant-layout .promptInputBox .input:hover {
  border: 1px solid #b041f3;
}

#builder div .content .layout .ant-layout .promptInputBox .input:focus {
  border: 1px solid #b041f3;
  background: #ffffff;
}

#builder div .content .layout .ant-layout .promptInputBox #darkGrey2 {
  width: 77px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

.ant-menu-title-content,
.ant-menu-title-content span {
  width: 100%;
}

#builder div .content .layout .ant-layout .proMembership {
  height: 500px;
  border-radius: 8px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to bottom, #848ff5, #b369ba);
  margin-top: 20px;
  padding: 0px 0 24px !important;
}

#builder div .content .layout .ant-layout .proMembership h1 {
  text-align: center;
  background: linear-gradient(to bottom, #848ff5, #b369ba);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 100px;
  line-height: 1.2;
}

#builder div .content .layout .ant-layout .proMembership h1 span {
  font-weight: 700;
}

#builder div .content .layout .ant-layout .proMembership .btnBox {
  width: 271px;
  height: 92px;
  border-radius: 50px;
  padding: 8px;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}

#builder div .content .layout .ant-layout .proMembership .btnBox div div {
  width: auto;
  padding: 0 24px;
}

#builder div .content .layout .ant-layout .proMembership .btnBox div .lock {
  width: 76px;
  height: 76px;
  border-radius: 100%;
  background: #ffffff;
}

#builder div .content .layout .ant-layout .proMembership .btnBox div .lock img {
  width: 25px;
}

#builder div .content .layout .ant-layout .proMembership .btnBox div div img {
  width: 25px;
}

#builder div .content .layout .ant-layout .proMembership .btnBox div div p {
  line-height: 1.2;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  margin: 0 0 0 10px;
}

#noSub .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  height: 0;
}

#builder div .content .layout .ant-layout .tabBox {
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
}

#builder div .content .layout .ant-layout .tabBox #blueBtn {
  width: auto;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 50px;
  /* margin: 0 8px 12px 0; */
}
#builder div .content .layout .ant-layout .tabBox #blueBtn:hover {
  background: #525162;
  color: #fff;
  border: 1px solid transparent;
}
#builder div .content .layout .ant-layout .inputBox > div {
  margin: 0 0 8px;
}

#builder div .content .layout .ant-layout .inputBox > div h1 {
  font-size: 18px;
  color: #0e0e1f;
  margin: 0 8px 0 0;
}

#builder div .content .layout .ant-layout .inputBox > div #whiteBtn {
  width: auto;
  padding: 6px 12px;
  border-radius: 50px;
  height: 32px;
  font-size: 13px;
}
#builder div .content .layout .ant-layout .inputBox .selectList {
  flex-wrap: wrap;
  display: flex;
  gap: 4px 8px;
}

#builder div .content .layout .ant-layout .inputBox .selectList button {
  height: 32px;
  margin: 0 0 4px;
}

#builder div .content .layout .ant-layout .inputBox .textAreaBox {
  background: #f1f1f1;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
#builder div .content .layout .ant-layout .inputBox .textAreaBox .ant-input-outlined,
#builder div .content .layout .ant-layout .inputBox .textAreaBox .textArea {
  height: 44px;
  color: #3e3d53;
  margin: 0 0 5px;
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}

/* #builder div .content .layout .ant-layout .inputBox .textAreaBox .ant-input-outlined {
  height: auto;
} */
.ant-popover .ant-popover-inner {
  background: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--gra-badge, #b041f3);
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}
.ant-popover .ant-popover-inner input:hover {
  background: #ffffff;

  border-color: #dfdce4;
  background: #fff;
}
.ant-popover .ant-popover-inner input + button {
  width: 45px;
  height: 39px;
  border-radius: 100%;
  padding: 8px;
}
.ant-popover .ant-popover-inner input + button:hover {
  box-shadow: none !important;
  border-color: #e8e2e9 !important;
  background: #fdf2ff !important;
}

.ant-popover .ant-popover-inner input + button span {
  background: url(/src/assets/images/common/i-checkPink.svg) no-repeat center;
  font-size: 0;
  width: 18px;
  height: 18px;
}
.ant-popover .ant-popover-arrow::after {
  z-index: 0;
  background: transparent;
  border-radius: 4px;

  background: #b041f3;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-top-width: 0;
}
/* #builder .ant-btn-default:hover {
  border-color: #dfdce4;
  background-color: #ffffff;
} */
.userPage .parameterBox .ant-btn-default {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #eaf2f8;
  color: #939ca7;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  height: 32px;
}
.userPage .parameterBox .ant-btn-default:hover {
  background: #525162;
  color: #fff !important;
  font-weight: 400;
}

/* 오른쪽 */
#builder div .right {
  width: 38%;
  /* max-height: 900px; */
  overflow: auto;
  justify-content: flex-start;
  gap: 12px;
}

#builder div .right > img {
  width: 24px;
  margin: 0 11px 0 0;
}

#builder div .right .title {
  font-size: 20px;
  color: #0e0e1f;
  margin: 0;
  padding: 0 0 4px;
  gap: 8px;
}

#builder div .right .title img {
  width: 24px;
  margin: 0;
}

#builder div .right .switchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#builder .inputBox .textBox:has(.ant-input-outlined:focus),
#builder .inputBox .textBox:has(.textArea:focus) {
  background: #fff;
  border: 1px solid #e94cf7;
}
/*#builder div .content .layout .textBox .textArea:not([readonly]) + .btnArea #darkGrey3 {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}*/

#builder div .content .layout .textBox .textArea:has([readonly]) > .textBox textarea.ant-input-outlined:focus + .btnArea button#darkGrey3 {
  background: #525162 !important;
  color: #fff;
}
#builder .textBox:has(> .textArea:not([readonly])) .btnArea #darkGrey3 {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}
#builder div .content .layout .ant-layout .inputBox .textAreaBox #darkGrey3 {
  width: 49px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}
#builder .textBox:has(textarea) {
  border: 1px solid #c718e2;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  margin: 0;
  gap: 20px;
}
/* #builder div .textBox textarea[readonly],
#builder div .textBox:has(textarea[readonly]:focus),
#builder div .textBox:has(> textarea[readonly]:hover),
#builder div .textBox:has(> textarea[readonly]:focus),
#builder div .textBox:has(> textarea[readonly])  */

#builder .textBox:has(textarea[readonly]) {
  background: #efecf1 !important;
  border-color: transparent;
}

#builder div .right .textBox .textArea {
  height: 100px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #3e3d53;
  font-weight: 500;
  line-height: 1.4;
}

#builder div .right .textBox div > div {
  width: auto;
  gap: 8px;
}

#builder div .right h2 {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
}

#builder div .right .selectBox {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 8px 8px 2px;
  margin: 0 0 12px;
  min-height: 72px;
}

#builder div .right .selectBox .select > div {
  padding: 8px;
  background: #ffffff;
  margin: 0 0 6px;
}

#builder div .right .selectBox .select > div div {
  width: auto;
  border-radius: 8px;
}

#builder div .right .selectBox .select > div > div img {
  width: 64px;
  height: 36px;
  border-radius: 4px;
  margin: 0 16px 0 0;
}

#builder div .right .selectBox .select > div > div h1 {
  font-size: 14px;
}

#builder div .right .selectBox .select > div > div #greyBtn3,
#builder div .right .selectBox .select > div > div #whiteBtn2 {
  width: auto;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 0 0 10px;
}

#builder div .right .save {
  padding: 20px 0;
  /* border-top: solid 1px rgba(0, 0, 0, 0.1); */
}

#builder div .right .save > div {
  justify-content: start;
  width: auto;
}
#builder div .right .save > div:last-child {
  justify-content: end;
  width: calc(100% / 2.5);
}

#builder div .right .save > div > p {
  font-size: 14px;
  color: #3e3d53;
  margin: 0 16px 0 0;
  font-weight: 600;
  line-height: 1.2;
  text-wrap-mode: nowrap;
}

#builder div .right .save > div #logoutBtn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #e2dfe4;
  height: 34px;
}

#builder div .right .save > div #logoutBtn p {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  text-wrap-mode: nowrap;
}
/* #builder div .right .save > div #logoutBtn p::after {
   background: url(/src/assets/images/common/i-arrCirR.svg) no-repeat center; 
  width: 16px;
  height: 16px;
  content: '';
  display: flex;
}*/
/* #builder div .right .save > div #logoutBtn div {
  display: none;
}
#builder div .right .save > div #logoutBtn img {
  width: 18px;
  margin: 0 0 0 8px;
} */
#builder div .right .save > div #purpleBtn {
  /* width: 200px; */
  height: 51px;
  border-radius: 24px;
}
#builder div .right .save > div #purpleBtn svg {
  font-size: 20px;
}

#saveModal {
  padding: 0 24px;
}

#saveModal > div {
  margin: 0 0 24px;
}

#saveModal div > h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: bold;
}

#saveModal div p {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#saveModal div p:hover {
  color: #c718e2;
}

#saveModal div h2 {
  font-size: 12px;
  color: #0e0e1f;
  margin: 60px 0 10px;
}

#saveModal div .input {
  width: 100%;
  height: 40px;
  background: #f4f5f7;
  border: 1px solid #efecf1;
  box-shadow: none;
  margin: 0 0 60px;
}

#saveModal div .input:hover {
  border: 1px solid #c718e2;
}

#saveModal div .input:focus {
  border: 1px solid #c718e2;
  background: #ffffff;
}

#saveModal div div #greyBtn,
#saveModal div div #purpleBtn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}

#congratsModal {
  padding: 0 24px;
}

#congratsModal div > h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: bold;
}

#congratsModal div p {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#congratsModal div p:hover {
  color: #c718e2;
}

#congratsModal div img {
  width: 40px;
  margin: 40px 0;
}

#congratsModal div h2 {
  font-size: 20px;
  color: #000000;
  text-align: center;
  line-height: 1.2;
  margin: 0 0 40px;
}

#congratsModal div #purpleBtn {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  font-size: 18px;
}

#builderWrapper .bgImg .builderBox > div {
  flex-wrap: wrap;
}

#builder div .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
#builder div .content:has(.builderGuide) {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
#builder div .content .layout .ant-layout {
  width: 100%;
  margin: 0;
}
#builder div .content .layout .ant-layout header {
  margin: 0;
  padding: 0;
  box-shadow: none;
  overflow: auto;
  /* border-right: 2px solid var(--bg-gray, #f3f3f3); */
}
#builder div .content .layout .ant-layout main {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
}
#builder div .content .layout .ant-layout main .builderGuideImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 200px;
}
/* #builder div .content .layout .ant-layout main .tabBox {
  margin-top: -24px;
} */
#builder div .content .layout .ant-layout aside .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#builder div .content .layout .ant-layout aside .btnArea button.ant-btn {
  height: 32px;
  padding: 0 8px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider {
  padding: 24px 16px !important;
  border: 2px solid var(--bg-gray, #f3f3f3);
  border-top: 0;
  border-bottom: 0;
  background: #fff;
  gap: 24px;
  min-height: calc(100vh - 638px);
  height: 100%;
}
#builder .ant-switch {
  height: 29px;
}
#builder .ant-switch-checked {
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#builder .ant-switcher_close::before {
  top: 6px;
}
#builder .ant-switch span {
  height: 22px;
}
#builder .ant-switch .ant-layout-sider {
  background: #fff;
}
#builder .ant-switch .ant-layout-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-layout-sider .ant-tree {
  margin-top: 12px;
}
#builder .ant-switch-handle {
  top: 4px;
  left: 4px;
  height: 22px;
  width: 22px;
  border-radius: 100px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-switch-handle ::before {
  border-radius: 100px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-switch-inner {
  height: 24px;
}
#builder div .content .layout .ant-layout .top {
  display: none;
}
#builder div .content .builderGuide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#builder div .content .builderGuide h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 48px 0 80px;
  line-height: 1.2;
}
#builder div .content .builderGuide h2 {
  color: var(--t-dark, #0e0e1f);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 32px;
}
#builder div .content .builderGuide h2 b {
  color: var(--t-pink, #c718e2);
  font-weight: 500;
  padding-bottom: 24px;
}
#builder div .content .builderGuide .tab {
  border: 0;
}
#builder div .content .listBox .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#builder div .content .listBox .list:has(.thumBorder[style='disply: block']) > .absoluteBox {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}
#builder div .content .listBox .list .absoluteBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#builder div .content .listBox .list .thumBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 4px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
}
#builder div .content .listBox .list .thumBorder :hover {
  border: 4px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
}
#builder div .content .listBox .list .btn-guide {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  bottom: 8px;
  z-index: 999;
}
#builder div .content .ant-switch .ant-switch-inner {
  padding-inline-start: 32px;
  padding-inline-end: 8px;
}
#builder div .headerMenu.ant-menu-horizontal > .ant-menu-item + li span {
  border-bottom-left-radius: 16px;
}
#builder div .selectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
}
#builder div .selectWrapper button {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
/* #builder div .right > h2 {
  position: relative;
}
#builder div .right > h2 button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 12px;
  width: 24px;
  height: 24px;
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat right 5px center;
  content: '';
}
#builder div .right > h2 button.btn-fold {
  transform: rotate(180deg);
  background-image: url(/src/assets/images/common/btn-downB.svg);
} */
#builder div .right .selectBox > div .ant-btn-default {
  min-width: auto;
}
#builder div #whiteBtn {
  min-width: 90px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  border-color: #ddd;
}
#builder div #whiteBtn img {
  width: 18px;
}
#builder div #whiteBtn p {
  font-size: 14px;
}
#builder div #darkGrey3 {
  min-width: 77px;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  /* margin: 0 0 0 5px; */
}
#builder div #darkGrey3 div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#builder div #darkGrey3 div img {
  width: 18px;
  margin: 0 8px 0 0;
}
#builder div #darkGrey3 div img.delete {
  width: 16px;
  padding: 2px;
}
#builder div #darkGrey3 p {
  font-size: 14px;
}
#builder div .save #purpleBtn {
  border-radius: 24px;
  width: 100%;
  padding: 20px;
  height: 52px;
}
#builder div .parameterBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
  padding: 28px 0;
  flex-wrap: wrap;
}

.soundEffectModal .top {
  margin: 0 0 20px;
}
.soundEffectModal .top h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: 700;
}
.soundEffectModal .top .svg {
  cursor: pointer;
}
.soundEffectModal .top .svg svg path {
  transition: all 0.2s ease-in-out;
}
.soundEffectModal .top .svg:hover svg path {
  fille: #ff5eb6;
}
.soundEffectModal .scrollBox {
  padding: 4px 0 4px 4px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  overflow: auto;
  height: 420px;
  align-items: flex-start;
  justify-content: flex-start;
}
.soundEffectModal .scrollBox div {
  min-width: 300px;
  width: 100%;
}
.soundEffectModal .btnBox {
  margin: 20px 0 0;
  justify-content: center;
}
.soundEffectModal .btnBox #purpleBtn,
.soundEffectModal .btnBox #logoutBtn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}
.soundEffectModal .btnBox #purpleBtn.btn-default,
.soundEffectModal .btnBox #logoutBtn.btn-default {
  border: 1px solid #525162;
}

/* 트리 */
.ant-tree-treenode {
  height: 32px;
  align-items: center;
  border-radius: 5px;
  width: 100%;
}

.ant-tree .ant-tree-switcher:before:hover,
.ant-tree .ant-tree-switcher:before:hover {
  background: transparent;
}

.ant-tree-treenode:hover {
  background: #f4f5f7;
}

.ant-tree .ant-tree-treenode.ant-tree-treenode-selected,
.ant-tree .ant-tree-treenode.ant-tree-treenode-selected {
  width: 100%;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  align-items: center;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: transparent;
}

/* .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  width: 108px;
  height: 32px;
} */
.ant-tree-treenode-selected::before {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%) !important;
  width: 95%;
  border-radius: 5px;
}

.create-prompt-menu {
  flex: 1;
  min-width: 0;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  padding: 1px;
  border-bottom: 0;
}

.ant-form-item-required {
  pointer-events: none; /* 마우스 이벤트 비활성화 */
}

.builderGuide {
  h1 {
    font-size: 48px;
    font-weight: 700;
    background: linear-gradient(to right, #13d5df, #f138fc);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    margin: 48px 0 40px;
  }
}

@media (max-width: 900px) {
  #builder div .right .selectBox .select > div > div img {
    display: none;
  }

  #builder div .content {
    flex-wrap: wrap;
  }

  #builder div .content .layout {
    width: 100%;
  }

  #builder div .right {
    width: 100%;
    max-height: 100%;
    padding: 20px 0;
    gap: 8px;
  }

  .selectWrapper .ant-select .ant-select-selector {
    background: #f4f5f7;
  }

  .selectWrapper {
    flex-wrap: wrap;
    margin: 0 0 20px;
    justify-content: space-between;
  }

  .selectWrapper .ant-select {
    width: calc(100% / 3 - 6px);
  }
  .selectWrapper button,
  .selectWrapper .ant-select:nth-child(4),
  .selectWrapper .ant-select:nth-child(5) {
    width: calc(100% / 2 - 6px);
    /* margin: 10px 0 0; */
  }

  #builder div .content .mobileTab {
    padding: 8px 12px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #dbdbe0;
    background: linear-gradient(to bottom, #fff, rgba(239, 236, 241, 0.5) 88%, #efecf1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  #builder div .content .mobileTab p {
    color: #0e0e1f;
    font-size: 14px;
    font-weight: 600;
  }

  #builder div .content .mobileTab#active {
    border-radius: 8px 8px 0 0;
    border-top: 1px solid #e94cf7;
    border-right: 1px solid #e94cf7;
    border-left: 1px solid #e94cf7;
    border-bottom: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 9%, rgba(255, 255, 255, 0) 15%, #fff), linear-gradient(to bottom, #fcedfd, #fcedfd);
    box-shadow: none;
  }

  #builder div .content .mobileTab#active p {
    color: #c718e2;
  }

  #builder > div .title {
    padding: 20px 0;
  }
  #builder .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
  }
  .ant-layout.ant-layout-has-sider,
  .ant-layout.ant-layout-has-sider {
    flex-direction: column;
  }

  #builder div .content .layout .ant-layout .top {
    margin: 20px 0;
  }

  #builder div .content .layout .ant-layout .top p {
    font-size: 16px;
    width: 60px;
  }

  #builder div .content .layout .ant-layout .top .line {
    width: calc(100% - 60px - 17px);
  }

  #builder div .content .layout .ant-layout .listBox .list {
    width: calc(100% / 2 - 10px);
    margin: 0 20px 20px 0;
  }

  #builder div .content .layout .ant-layout .listBox .list:nth-child(4n) {
    margin: 0 20px 20px 0;
  }

  #builder div .content .layout .ant-layout .listBox .list:nth-child(2n) {
    margin: 0 0 20px;
  }

  #builder div .content .layout .ant-layout .listBox {
    max-height: 100%;
    overflow: initial;
  }

  #builder div .content .layout .ant-layout .menuBox {
    margin: 20px 0 0;
  }

  #builder div .right .save {
    border-top: none;
  }
  #builder div .right .save > div:last-child,
  #builder div .right .save > div {
    width: 100%;
  }

  #builder div .right .save > div #purpleBtn {
    width: 100%;
    height: 45px;
    margin: 36px 0 20px;
    font-size: 16px;
  }

  #builder div .content .layout .ant-layout .inputBox > div {
    justify-content: space-between;
  }

  #builder div .content .layout .ant-layout .inputBox {
    border-bottom: none;
  }
}
@media (max-width: 800px) {
  #builder div .selectWrapper {
    padding: 10px 0;
  }
  #builder > div .tab {
    padding: 0 0 2px;
    border-bottom: none;
  }
  .selectWrapper {
    flex-wrap: wrap;
    margin: 0;
  }
  #builder div .content .layout .ant-layout .ant-layout-has-sider {
    flex-direction: column;
  }
  #builder div .content .layout .ant-layout header {
    overflow: hidden;
  }
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected .ant-menu-title-content,
  #builder div .content .layout .ant-layout .ant-menu.headerMenu .ant-menu-title-content,
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item,
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item {
    height: 40px;
    line-height: 1;
    font-size: 12px;
  }
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item {
    min-width: 90px;
  }
  #builder div .content .layout .ant-layout main .builderGuideImg {
    height: 130px;
  }
}

@media (max-width: 700px) {
  #prompt > div .title {
    justify-content: flex-start;
    padding: 20px 0 0;
  }

  #prompt > div .title > div {
    margin: 0 20px 0 0;
  }

  #prompt > div .title div h1 {
    font-size: 26px;
  }

  #builder > div .tab {
    padding: 0;
    border-bottom: none;
  }
  #builder .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
  }
  #builder div .selectWrapper button,
  #builder div .content .layout .selectWrapper .ant-select,
  .ant-select-single {
    height: 32px;
  }
  #builder div .selectWrapper button {
    margin-top: -10px;
  }
  #builder div .content .layout .ant-layout aside .btnArea button[role='switch'] {
    width: 72px;
  }
}
