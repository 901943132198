article > .top {
  padding: 50px 0;
  background: linear-gradient(180deg, rgba(131, 223, 227, 0.2) 0%, rgba(225, 182, 228, 0.2) 100%);
}

article .top .rsWrapper {
  align-items: flex-start;
}
#myHome .top .rsWrapper {
  align-items: center;
  z-index: 99;
}
article .top .rsWrapper .left .imgBox > div {
  border-radius: 16px;
  overflow: hidden;
}
article .top .rsWrapper .left,
article .top .rsWrapper .right {
  width: calc(50% - 32px);
  /* border-radius: 16px;
  overflow: hidden; */
}
article .top .rsWrapper .right {
  gap: 12px;
}

article .top .rsWrapper .left .user {
  padding: 0 8px 10px;
}

article .top .rsWrapper .left .user div {
  width: auto;
}

article .top .rsWrapper .left .user .flexColEnd img {
  margin: 0;
}

article .top .rsWrapper .left .user div p {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
}

article .top .rsWrapper .left .user div p {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  line-height: 100%;
  text-align: center;
  color: #fff;
  width: auto;
  font-size: 11px;
}
article .top .rsWrapper .left .user > img {
  display: none;
}
.ant-image-preview-close {
  background: transparent;
}
.ant-image-preview-close > .anticon {
  font-size: 32px;
}
.ant-image-preview-root .ant-image-preview-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
}
#detail .thumbnail .ant-image .ant-image-mask:hover {
  opacity: 0;
}
article .top .rsWrapper .left .box16 {
  display: flex;
  width: 100%;
  border-radius: 16px;
}
article .top .rsWrapper .left .imgScroll {
  height: 884px;
  overflow: auto;
  justify-content: flex-start;
  align-items: start;
}

article .top .rsWrapper .left .imgScroll > div {
  width: 100%;
  gap: 12px;
}

article .top .rsWrapper .left .imgScroll div .activeSiren,
article .top .rsWrapper .left .imgBox div .activeSiren {
  /* margin: 0 0 12px; */
  background: rgba(0, 0, 0, 0.05);
  gap: 2px;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div,
article .top .rsWrapper .left .imgBox div .activeSiren div {
  width: calc(100% / 5);
  position: relative;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div:before,
article .top .rsWrapper .left .imgBox div .activeSiren div:before {
  content: '';
  padding: 0 0 100%;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div img,
article .top .rsWrapper .left .imgBox div .activeSiren div img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

article .top .rsWrapper .left .imgScroll div .thumbnail,
article .top .rsWrapper .left .imgBox div .thumbnail {
  position: relative;
  overflow: hidden;
  /* border-radius: 20px; */
  /* margin: 0 0 13px; */
}

article .top .rsWrapper .left .imgScroll div .thumbnail::after,
article .top .rsWrapper .left .imgBox div .thumbnail::after {
  content: '';
  padding: 0 0 100%;
}

article .top .rsWrapper .left .imgScroll div .thumbnail > img,
article .top .rsWrapper .left .imgBox div .thumbnail > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 10;
  align-items: flex-end;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div {
  width: auto;
  cursor: pointer;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div > img {
  width: 26px;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon {
  position: relative;
  width: 26px;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon:hover img.activeIcon,
article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon#active img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon:hover img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon#active img.activeIcon {
  opacity: 1;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon img,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon img {
  width: 100%;
  height: auto;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon img.activeIcon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon:first-child,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon:first-child {
  margin: 0 0 20px;
}

article .top .rsWrapper .left .barBox {
  margin: 0 0 16px;
}

article .top .rsWrapper .left .barBox .bar {
  position: relative;
  margin: 0 0 5px;
}

article .top .rsWrapper .left .barBox .bar .activeBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}

article .top .rsWrapper .left .barBox p {
  font-size: 11px;
  color: #656262;
}

article .top .rsWrapper .left .barBox span {
  color: #0e0e1f;
}

article .top .rsWrapper .left .play {
  margin: 0 0 35px;
}

article .top .rsWrapper .left .play img {
  width: 30px;
  margin: 0 50px 0 0;
}

article .top .rsWrapper .left .play img:nth-child(5) {
  margin: 0;
}

article .top .rsWrapper .left #purpleBtn {
  width: 200px;
  height: 34px;
  border-radius: 4px;
}

article .top .rsWrapper .left #purpleBtn img {
  width: 20px;
  margin: 0 10px 0 0;
}

article .top .rsWrapper .left #purpleBtn {
  font-size: 14px;
}

article .siren {
  width: 24px;
  cursor: pointer;
}

article .siren img {
  width: 100%;
}

article .siren img:hover {
  content: url(/src/assets/images/common/i-sirenOn.svg) 
}

article .top .rsWrapper .right > h1 {
  font-size: 36px;
  font-weight: 600;
  color: #0e0e1f;
  margin: -8px 0 16px;
  width: 100%;
  line-height: 1.2;
  letter-spacing: -1.8px;
}

article .top .rsWrapper .right .user .userIcon {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  padding: 0;
  background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
}

article .top .rsWrapper .right .user .userIcon > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

article .top .rsWrapper .right .user .userIcon p {
  font-size: 40px;
  color: #ffffff;
}

article .top .rsWrapper .right .user > div {
  width: calc(100% - 80px - 15px);
  padding: 0 0 0 15px;
  gap: 4px;
}

article .top .rsWrapper .right .user div .grade div {
  width: auto;
  padding: 8px 4px;
  border-radius: 20px;
  background: #f8ab30;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 8px 0 0;
}

article .top .rsWrapper .right .user div h2 {
  font-size: 24px;
  font-weight: 500;
  color: #0e0e1f;
}

article .top .rsWrapper .right .user div .grade {
  /* margin: 0 0 7px; */
}

article .top .rsWrapper .right .user div p {
  font-size: 16px;
  font-weight: 500;
  color: #0e0e1f;
}

article .top .rsWrapper .right .user {
  /* margin: 0 0 15px; */
  align-items: flex-start;
}

article .top .rsWrapper .right .content {
  padding: 16px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  margin: 0 0 24px;
}

article .top .rsWrapper .right .content .typeBox {
  flex-wrap: wrap;
}

article .top .rsWrapper .right .content .typeBox .type {
  width: auto;
  padding: 8px 12px;
  border-radius: 24px;
  background: #ffffff;
  color: #3e3d53;
  margin: 0 12px 16px 0;
  height: 32px;
  font-weight: 500;
}

article .top .rsWrapper .right .content .text {
  margin: 0 0 16px;
}

article .top .rsWrapper .right .content .stirenText {
  justify-content: flex-start;
  height: 180px;
  overflow: hidden;
}

article .top .rsWrapper .right .content .text {
  justify-content: flex-start;
  height: 395px;
  overflow: hidden;
}

article .top .rsWrapper .right .content .stirenText p,
article .top .rsWrapper .right .content .text p {
  width: 100%;
  font-size: 14px;
  line-height: 1.6;
  color: #323146;
  height: 100%;
  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
  margin: 0 0 20px;
}

article .top .rsWrapper .right .content .stirenText .more p,
article .top .rsWrapper .right .content .text .more p {
  width: auto;
}

article .top .rsWrapper .right #greyBtn5 {
  width: 163px;
  height: 34px;
  border-radius: 24px;
  margin: 0 0 24px;
}

article .top .rsWrapper .right #greyBtn5 img {
  width: 18px;
  margin: 0 10px 0 0;
}

article .top .rsWrapper .right #greyBtn5 p {
  font-size: 14px;
}

article .top .rsWrapper .right #logoutBtn {
  width: 163px;
  height: 34px;
  border-radius: 24px;
  margin: 0 0 24px;
}

article .top .rsWrapper .right #logoutBtn img {
  width: 18px;
  margin: 0 10px 0 0;
}

article .top .rsWrapper .right #logoutBtn p {
  font-size: 14px;
}

article .top .rsWrapper .right .priceBox > h1 {
  font-size: 32px;
  font-weight: 600;
  color: #0e0e1f;
}

#detail .top .rsWrapper .right .priceBox > div {
  width: auto;
}

article .top .rsWrapper .right .priceBox #purpleBtn {
  position: relative;
  margin: 0 0 0 16px;
}

article .top .rsWrapper .right .priceBox #purpleBtn img.markIcon {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 28px;
}

article .top .rsWrapper .right .priceBox #darkGrey,
article .top .rsWrapper .right .priceBox #purpleBtn {
  width: 273px;
  height: 51px;
  border-radius: 24px;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}

article .top .rsWrapper .right .priceBox #darkGrey img,
article .top .rsWrapper .right .priceBox #purpleBtn img {
  width: 20px;
  margin: 0 10px 0 0;
}

article .top .rsWrapper .right .priceBox .downloadBox #purpleBtn {
  width: 200px;
  height: 34px;
  /* border-radius: 4px; */
  font-size: 14px;
}
article .top .rsWrapper .right .priceBox .downloadBox #purpleBtn:hover {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  color: #fff;
}

/* 서브메인 상세페이지 */
#detail .majorModel {
  padding: 4px 0;
}
#detail .majorModel .title {
  color: #8790a3;
  font-size: 12px;
  font-weight: 600;
}
#detail .userInfo > div:first-child{
  gap:10px;
}
#detail .iconBox {
  display: flex;
  align-items: center;
  gap: 10px;
}
#detail .top .rsWrapper .right .userInfo .icon {
  position: relative;
  width: 26px;
  padding: 0;
  cursor: pointer;
}
#detail .top .rsWrapper .right .userInfo .icon img {
  width: 100%;
  height: auto;
}
#detail .top .rsWrapper .right .userInfo .icon img.activeIcon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}
#detail .top .rsWrapper .right .userInfo .icon:hover img.activeIcon,
#detail .top .rsWrapper .right .userInfo .icon#active img.activeIcon {
  opacity: 1;
}
#detail .genreInfo {
  display: flex;
  align-items: center;
  width: 100%;
}
#detail .genreInfo li span{
  display: inline-block;
  height: 30px;
  padding: 0 12px;
  border-radius: 24px;
  background-color: #525162;
  line-height: 30px;
  color: #fff;
}
#detail .genreInfo li::after {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  background: url(/src/assets/images/common/arrow.svg) no-repeat center;
  background-size: contain;
}
#detail .genreInfo li:last-child::after {
  display: none;
}
#detail .top .rsWrapper .right .priceBox .downloadBox button:disabled,
#detail .top .rsWrapper .right .mobilePrice button:disabled {
  background: #dfdce4;
  cursor: not-allowed;
}
#detail .top .rsWrapper .right .content .tagBox {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
#detail .top .rsWrapper .right .content .tagBox li {
  min-width: 50px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: #3e3d53;

}
#detail .top .rsWrapper .right .content .text {
  height: auto;
  /* min-height: 268px !important; */
}
#detail .top .rsWrapper .right .content .text .prptDetailAccordion {
  width: 100%;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border: 0;
  /* background: rgba(255, 255, 255, 0.5); */
  border-radius: 12px 12px 0 0;
  padding: 8px 8px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  height: 22px;
  display: flex;
  align-items: center;
  padding-inline-end: 12px;
  width: 36px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  transition: transform 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.3s;
  fill: #ec15ff;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  padding: 4px;
  /* fill: rgba(0, 0, 0, 1); */
}
#detail .top .rsWrapper .right .content .text .prptDetailAccordion .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
}
#detail .top .rsWrapper .right .content .text textarea {
  padding: 0;
  background-color: unset;
  border-width: 0;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  color: #323146;
  line-height: 1.4;
}
#detail .top .rsWrapper .right .content .text textarea:disabled {
  cursor: default;
}
#detail #musicBox .rhap_progress-section .separator {
  position: absolute;
  right: 31px;
  font-size: 11px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.88);
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-block: 0;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
#detail .rsWrapper .btnArea {
  width: 100%;

  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
  padding: 40px 0;
  border-radius: 8px;
}
#detail .rsWrapper .btnArea .approval {
  min-width: 120px;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  height: 40px;
}
#detail .rsWrapper .btnArea .approval button {
  font-size: 14px;
  font-weight: 500;
  font-family: Pretendard;
  color: #fff;
  margin: 0;
}
#detail .rsWrapper .promptGuide {
  margin: 0 0 20px;
}
#detail .rsWrapper .promptGuide .more {
  margin: 10px 0 0;
}
#detail .rsWrapper .promptGuide .more p {
  cursor: pointer;
  text-decoration: underline;
}
#detail .rsWrapper .reviewCreate {
  margin: 50px 0 40px;
  gap: 40px;
  align-items: flex-end;
}
#detail .rsWrapper .reviewCreate .create {
  /* padding: 0 40px 0 0; */
}
#detail .top {
  margin: 0 0 20px;
}
#detail .reviewList {
  margin: 20px 0 0;
}
#detail .user .classRank .ant-dropdown-trigger img,
#detail .user .classRank .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item img {
  width: 20px;
  /* height: 20px; */
  cursor: pointer;
}
#detail .user .classRank .ant-dropdown-trigger p,
#detail .user .classRank .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item p {
  margin: 0 5px;
}
#detail .promptThumList.forceWidth {
  width: 100%;
}
article .rsWrapper .tip {
  padding: 24px 8px 0;
  height: 100px;
  justify-content: flex-start;
  overflow: auto;
}

article .tip div h1 {
  font-size: 14px;
  font-weight: bold;
  color: #323146;
  margin: 0 0 24px;
}

article .tip .text p {
  font-size: 14px;
  color: #323146;
  overflow: hidden;
  height: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
  font-weight: 400;
  line-height: 1.4;
}

article .rsWrapper .prompt {
  margin: 40px 0 0 0;
}

article .rsWrapper .prompt h1 {
  font-size: 32px;
  font-weight: bold;
  color: #0e0e1f;
  margin: 0 0 30px;
}
article .rsWrapper .prompt {
  margin: 40px 0 30px;
}

article .rsWrapper .prompt > div {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ec15ff;
  background: rgba(255, 182, 228, 0.2);
}

article .rsWrapper .prompt > div textarea.ant-input-outlined {
  background: transparent;
  border: none;
  box-shadow: none;
  font-weight: 500;
  line-height: 1.4;
}

article .rsWrapper .prompt > div .btnBox {
  height: 40px;
}

article .rsWrapper .prompt > div .btnBox > p {
  font-size: 14px;
  padding: 0 0 0 12px;
}

article .rsWrapper .prompt > div .btnBox #pinkBtn {
  width: 77px;
  height: 40px;
  border-radius: 4px;
}

article .rsWrapper .reviewBox {
  margin: 40px 0 44px 0;
}

article .rsWrapper .reviewBox div {
  width: auto;
}

article .rsWrapper .reviewBox h1 {
  font-size: 32px;
  font-weight: bold;
  color: #0e0e1f;
  margin: 0 40px 0 0;
}

article .rsWrapper .reviewBox div h2 {
  color: #0e0e1f;
  font-size: 16px;
  font-weight: 600;
  margin: 0 8px 0 0;
}

article .rsWrapper .reviewBox div p {
  font-size: 14px;
  color: #87858b;
  margin: 0 0 0 5px;
}

article .rsWrapper .reviewCreate {
  margin: 0 0 40px;
}

article .rsWrapper .reviewCreate .review {
  width: auto;
}

article .rsWrapper .reviewCreate .review p {
  font-size: 16px;
  color: #0e0e1f;
  margin: 0 0 24px;
}

article .rsWrapper .reviewCreate .create {
  /* width: calc(100% - 224px - 180px); */
  /* padding: 0 40px; */
}

article .rsWrapper .reviewCreate .create p {
  font-size: 24px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 12px;
}

article .rsWrapper .reviewCreate .create div .input:focus {
  border: 1px solid #b041f3;
  background: #ffffff;
}

article .rsWrapper .reviewCreate .create div .input {
  width: calc(100% - 73px - 12px);
  height: 48px;
  border-radius: 4px;
  background: #f4f5f7;
  padding: 12px;
  background: #f4f5f7;

  border: 1px solid var(--b-input, #dfdce4);
  box-shadow: none;
}

article .rsWrapper .reviewCreate .create div .input::placeholder {
  color: #a3a1a7;
}

article .rsWrapper .reviewCreate .create div #greyBtn {
  width: 73px;
  height: 48px;
  font-size: 14px;
  border-radius: 4px;
}

article .rsWrapper .reviewCreate .question {
  max-width: 224px;
}

article .rsWrapper .reviewCreate .question > p {
  font-size: 16px;
  font-weight: 600;
  color: #0e0e1f;
  margin: 0 0 12px;
}

article .rsWrapper .reviewCreate .question #darkGrey2:hover .hoverIcon {
  opacity: 1;
}

article .rsWrapper .reviewCreate .question #darkGrey2 .hoverIcon {
  opacity: 0;
  position: absolute;
  left: 39px;
}

article .rsWrapper .reviewCreate .question #darkGrey2 {
  width: 100%;
  height: 48px;
  border-radius: 50px;
  position: relative;
}
article .rsWrapper .reviewCreate .question #darkGrey2:hover {
  background: var(--bg-btnDark, #525162);
  color: #fff;
}

article .rsWrapper .reviewCreate .question #darkGrey2:hover .ques::before {
  background-image: url(/src/assets/images/common/i-quesW.svg);
}

article .rsWrapper .reviewCreate .question #darkGrey2 img {
  width: 25px;
  margin: 0 10px 0 0;
}

article .rsWrapper .reviewCreate .question #darkGrey2 p {
  font-size: 13px;
  font-weight: 600;
}

#detail .rsWrapper .reviewList .list {
  width: 50%;
  padding: 16px;
  border-radius: 70px 70px 16px 70px;
  background: rgba(62, 61, 93, 0.1);
  align-items: flex-end;
  margin: 0 0 24px;
}

#detail .rsWrapper .reviewList .list.answer {
  width: 100%;
  padding: 16px;
  border-radius: 70px 70px 16px 70px;
  background: rgba(62, 61, 93, 0.1);
  align-items: flex-end;
  margin: 0 0 24px;
}

#detail .rsWrapper .reviewList .list .userReview {
  align-items: flex-start;
}

#detail .rsWrapper .reviewList .list.answer .userReview {
  width: 50%;
  align-items: flex-start;
}

#detail .rsWrapper .reviewList .list .userReview > img {
  width: 140px;
  height: 140px;
  border-radius: 100%;
}

#detail .rsWrapper .reviewList .list .userReview > div {
  width: calc(100% - 140px);
  padding: 0 32px;
}

#detail .rsWrapper .reviewList .list .userReview > div .top {
  margin: 0 0 13px;
}

#detail .rsWrapper .reviewList .list .userReview > div .top div {
  width: auto;
}

#detail .rsWrapper .reviewList .list .userReview > div .top div p {
  font-size: 16px;
  color: #0e0e1f;
  border-bottom: 1px solid #0e0e1f;
}

#detail .rsWrapper .reviewList .list .userReview > div .top #darkGreyBorder {
  width: 45px;
  height: 30px;
  font-size: 12px;
  border-radius: 24px;
}

#detail .rsWrapper .reviewList .list .userReview > div .rateBox {
  margin: 0 0 12px;
}

#detail .rsWrapper .reviewList .list .userReview > div .rateBox .line {
  height: 18px;
  width: 1px;
  background: #d7d4db;
  margin: 0 12px;
}

#detail .rsWrapper .reviewList .list .userReview > div .rateBox p {
  font-size: 12px;
  color: #6d6c75;
  font-weight: 400;
}

#detail .rsWrapper .reviewList .list .userReview > div .content {
  font-size: 14px;
  color: #0e0e1f;
  line-height: 1.4;
}

#detail .rsWrapper .reviewList .list .userReview > div #darkGreyBorder.mobileBtn {
  display: none;
}

#detail .rsWrapper .reviewList .list .answer {
  width: 50%;
  padding: 16px 16px 16px 72px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 70px 12px 12px 70px;
  flex-wrap: wrap;
}

#detail .rsWrapper .reviewList .list .answer > div {
  margin: 0 0 20px;
}

#detail .rsWrapper .reviewList .list .answer > div > div {
  width: auto;
}

#detail .rsWrapper .reviewList .list .answer > div > div h1 {
  font-size: 16px;
  color: #0e0e1f;
}

#detail .rsWrapper .reviewList .list .answer > div > div p {
  font-size: 12px;
  color: #6d6c75;
  margin: 0 0 0 20px;
}

#detail .rsWrapper .reviewList .list .answer > div > p {
  font-size: 18px;
  color: #6d6c75;
}

#detail .rsWrapper .reviewList .list .answer .content {
  font-size: 14px;
  color: #0e0e1f;
  margin: 0;
  text-wrap-mode: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

#detail .rsWrapper .reviewList .list .answer .input {
  width: calc(100% - 73px - 12px);
  height: 48px;
  background: #f4f5f7;
  padding: 16px 12px;
  font-size: 13px;
  border: none;
  box-shadow: none;
}

#detail .rsWrapper .reviewList .list .answer #greyBtn {
  width: 73px;
  height: 48px;
  border-radius: 4px;
}

#detail .rsWrapper .allReview {
  margin: 0 0 80px;
}

#detail .rsWrapper .allReview p {
  font-size: 14px;
  color: #0e0e1f;
  font-weight: bold;
  border-bottom: 2px solid #0e0e1f;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#detail .rsWrapper .allReview p:hover {
  color: #ec15ff;
  border-bottom: 2px solid #ec15ff;
}

article .rsWrapper .allReview {
  margin: 0 0 80px;
}

article .rsWrapper .allReview p {
  font-size: 14px;
  color: #0e0e1f;
  font-weight: bold;
  border-bottom: 2px solid #0e0e1f;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

article .rsWrapper .allReview p:hover {
  color: #ec15ff;
  border-bottom: 2px solid #ec15ff;
}

article .rsWrapper .promptBox {
  position: relative;
  margin: 0 0 30px;
}

article .rsWrapper .promptBox .line {
  position: absolute;
  top: 50%;
  left: 0;
  border: 1px dashed #c2b8cb;
  margin: -0.5px 0 0;
  width: 100%;
}

article .rsWrapper .promptBox > div {
  padding: 0 24px;
  background: #ffffff;
  z-index: 2;
  width: auto;
}

article .rsWrapper .promptBox > div img {
  width: 20px;
}

article .rsWrapper .promptBox > div p {
  font-size: 20px;
  margin: 0 12px;
  color: #3e3d53;
  font-weight: 700;
}

article .rsWrapper .promptBox > div #greyBtn {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 14px;
}

article .rsWrapper .slide {
  margin: 0 0 52px;
}

#reviewModal .reviewTitle {
  margin: 0 0 20px;
  padding: 8px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

#reviewModal .reviewTitle h1,
#reviewModal .reviewTitle p {
  font-size: 24px;
  color: #0e0e1f;
}

#reviewModal .reviewTitle p {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#reviewModal .reviewTitle p:hover {
  color: #ec15ff;
}

#reviewModal .reviewBox {
  margin: 0 0 25px;
}

#reviewModal .reviewBox h2 {
  font-size: 24px;
  font-weight: bold;
  color: #0e0e1f;
  margin: 0 40px 0 0;
}

#reviewModal .reviewBox h3 {
  color: #0e0e1f;
  font-size: 16px;
  margin: 0 5px 0 0;
}

#reviewModal .reviewBox p {
  font-size: 14px;
  color: #87858b;
  margin: 0 0 0 5px;
  border-bottom: 1px solid #87858b;
  line-height: 1;
}

#reviewModal .listBox {
  height: 500px;
  justify-content: flex-start;
  overflow: auto;
}

#reviewModal .listBox .listScroll .list {
  padding: 16px;
  border-radius: 70px 70px 16px 70px;
  background: rgba(62, 61, 93, 0.1);
  align-items: flex-end;
  margin: 0 0 24px;
  flex-wrap: wrap;
}

#reviewModal .listBox .listScroll .list .userReview {
  align-items: flex-start;
}

#reviewModal .listBox .listScroll .list .userReview > img {
  width: 140px;
  height: 140px;
  border-radius: 100%;
}

#reviewModal .listBox .listScroll .list .userReview > div {
  width: calc(100% - 140px);
  padding: 0 32px;
}

#reviewModal .listBox .listScroll .list .userReview > div .top {
  margin: 0 0 13px;
}

#reviewModal .listBox .listScroll .list .userReview > div .top p {
  font-size: 16px;
  color: #0e0e1f;
}

#reviewModal .listBox .listScroll .list .userReview > div .top #greyBtn {
  width: 45px;
  height: 30px;
  font-size: 12px;
  border-radius: 24px;
}

#reviewModal .listBox .listScroll .list .userReview > div .rateBox {
  margin: 0 0 12px;
}

#reviewModal .listBox .listScroll .list .userReview > div .rateBox .line {
  height: 18px;
  width: 1px;
  background: #d7d4db;
  margin: 0 12px;
}

#reviewModal .listBox .listScroll .list .userReview > div .rateBox p {
  font-size: 12px;
  color: #6d6c75;
}

#reviewModal .listBox .listScroll .list .userReview > div .content {
  font-size: 14px;
  color: #0e0e1f;
  line-height: 1.4;
}

#reviewModal .listBox .listScroll .list .answer {
  padding: 16px 16px 16px 72px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0 0 0 70px;
  margin: 24px 0 0;
}

#reviewModal .listBox .listScroll .list .answer div {
  align-items: flex-end;
}

#reviewModal .listBox .listScroll .list .answer div h1 {
  font-size: 16px;
  color: #0e0e1f;
  margin: 0 8px 0 0;
}

#reviewModal .listBox .listScroll .list .answer div p {
  font-size: 12px;
  color: #6d6c75;
}

#reviewModal .listBox .listScroll .list .answer > p {
  font-size: 14px;
  color: #0e0e1f;
  margin: 16px 0 0;
}

.imageModal {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 8px;
}

.imageModal > div {
  position: relative;
  height: 100%;
}

.imageModal .pagination {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.imageModal .pagination h1 {
  font-size: 14px;
  color: #ffffff;
  margin: 0 42px 0 0;
}

.imageModal .pagination p {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.imageModal .pagination p:hover {
  color: #ec15ff;
}

.rhap_progress-bar-show-download {
  background: rgba(255, 255, 255, 0.5);
}

.rhap_progress-bar {
  height: 2px;
}

#musicBox .rhap_progress-section .rhap_progress-container .rhap_progress-indicator {
  height: 4px;
  top: -1px;
}

#musicBox .rhap_controls-section {
  margin-top: 12px;
  height: 32px;
}
#musicBox .rhap_controls-section div {
  display: flex;
  align-items: center;
  height: 100%;
}
#musicBox .rhap_controls-section div button {
  height: 100%;
}
#musicBox .top .tavBox {
  background: rgba(255, 255, 255, 0.3);
}
#musicBox .listBox {
  background: rgba(255, 255, 255, 0.3);
  gap: 8px;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#musicBox .rhap_controls-section .rhap_shuffle-controls .rhap_button-shuffle {
  padding: 8px 12px;
}

article .top .rsWrapper .right .mobileUser,
article .top .rsWrapper .right .mobilePrice,
article .rsWrapper .mobileQuestion,
article .rsWrapper .reviewList .list .userReview > div .top div img,
article .rsWrapper .reviewList .list .userReview > div .top div .mobileRate,
article .rsWrapper .reviewList .list .userReview > div .top > p,
article .rsWrapper .reviewList .list .mobileBtn,
article .top .rsWrapper .right .mobilePrice #purpleBtn {
  display: none;
}

/* 나중에 추가된 텍스트 프롬프트 돌아가기 버튼 */
article#detail .top .rsWrapper .right .orderFirst button {
  display: none;
}

@media (max-width: 1400px) {
  article .top .rsWrapper .right .priceBox #darkGrey,
  article .top .rsWrapper .right .priceBox #purpleBtn {
    width: 35%;
  }

  article .top .rsWrapper .right .content .text {
    height: 210px;
  }
}

@media (max-width: 1280px) {
  article .top .rsWrapper .right .content .text {
    height: 130px;
  }
}

@media (max-width: 900px) {
  article .top .rsWrapper .left,
  article .top .rsWrapper .right {
    width: 100%;
  }
  article .top .rsWrapper,
  article .rsWrapper .reviewList .list {
    flex-wrap: wrap;
  }
  #detail .rsWrapper .promptGuide {
    margin: 0 0 20px;
    padding: 0;
  }
  article .rsWrapper .reviewList .list .userReview,
  article .rsWrapper .reviewList .list .answer {
    width: 100%;
    margin: 0 0 30px;
  }
  article .rsWrapper .tip {
    padding: 30px 8px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  article .top .rsWrapper .left .imgBox div .thumbnail {
    margin: 0;
  }
  article .top .rsWrapper .left .play img {
    width: 20px;
    margin: 0 40px 0 0;
  }
  article .top .rsWrapper .left .imgScroll > div {
    width: 100%;
    gap: 3px;
  }
  article .top .rsWrapper .left #purpleBtn,
  article .top .rsWrapper .right .priceBox > h1,
  article .top .rsWrapper .right #greyBtn5,
  article .top .rsWrapper .right #logoutBtn,
  /* article .rsWrapper .reviewCreate .question, */
  article .rsWrapper .reviewCreate .review p,
  /* article .rsWrapper .reviewCreate .create p, */
  article .rsWrapper .reviewCreate .create div .input,
  article .rsWrapper .reviewList .list .userReview > img,
  article .rsWrapper .reviewList .list .userReview > div .top #greyBtn,
  article .rsWrapper .reviewList .list .userReview > div .rateBox {
    display: none;
  }

  article#detail .top .rsWrapper .right {
    gap: 12px;
    padding-top: 30px;
  }

  article#detail .top .rsWrapper .right > * {
    order: 2;
  }

  article#detail .top .rsWrapper .right > .siren {
    /* 신고버튼 */
    display: none;
  }

  article#detail .top .rsWrapper .right > h1 {
    margin: 0;
    font-size: 20px;
  }

  /* 유저정보 */
  article#detail .top .rsWrapper .right .user {
    order: 1;
    margin: 0;
  }

  article#detail .top .rsWrapper .right .user .userIcon {
    width: 32px;
    height: 32px;
    margin-top: 4px;
  }

  article#detail .top .rsWrapper .right .user .grade {
    margin: 0;
  }

  article#detail .top .rsWrapper .right .user div .grade div {
    width: auto;
    padding: 3px 7px;
    font-size: 11px;
    margin: 0 8px 0 0;
  }

  article#detail .top .rsWrapper .right .user div h2 {
    font-size: 16px;
  }

  article#detail .top .rsWrapper .right .userInfo {
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  #detail .top .rsWrapper .right .genreInfo {
    order: 3;
  }

  article#detail .top .rsWrapper .right .content {
    order: 3;
    margin: 20px 0 0 0;
    /* gap: 20px; */
  }

  article .top .rsWrapper .right .priceBox {
    display: none;
  }

  article .top .rsWrapper .right .priceBox #darkGrey,
  article .top .rsWrapper .right .priceBox #purpleBtn {
    width: 49%;
  }

  article .top .rsWrapper .right .mobilePrice {
    display: flex;
  }

  article .top .rsWrapper .right .mobilePrice h1 {
    font-size: 26px;
    color: #0e0e1f;
    font-weight: 600;
  }

  article .top .rsWrapper .right .mobilePrice #purpleBtn {
    width: 95px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    padding: 0 8px;
  }
  #detail .majorModel {
    padding: 0 0;
  }
  #detail .top {
    margin: 0;
  }
  #detail .rsWrapper .reviewList .list .userReview > div .top {
    margin: 0 0 8px;
  }
  article .rsWrapper .reviewCreate .create p {
    font-size: 20px;
  }
  #detail .top .rsWrapper .right .content .text {
    height: auto;
    min-height: 100px !important;
  }
  article .top .rsWrapper .right .user > div {
    gap: 2px;
  }
  #detail .rsWrapper .reviewList .list .answer > div {
    margin: 0 0 16px;
  }
  article .top .rsWrapper .right .mobilePrice #purpleBtn img {
    width: 15px;
    margin: 0 10px 0 0;
  }
  #detail .rsWrapper .reviewList .list .userReview > div .content {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
  article .top .rsWrapper .right .mobilePrice #purpleBtn p {
    font-size: 14px;
  }

  article .top .rsWrapper .right .content .typeBox .type {
    font-size: 12px;
    margin: 0 8px 8px 0;
  }

  article .rsWrapper .prompt h1 {
    margin: 10px 0;
    font-size: 20px;
  }

  article .rsWrapper {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  article .rsWrapper > p {
    font-size: 14px;
    font-weight: 600;
    color: #0e0e1f;
    margin: 0 0 20px;
  }

  #detail .rsWrapper .btnArea {
    padding: 30px 0 50px;
  }
  #detail .rsWrapper .btnArea .approval {
    min-width: 120px;
  }
  article .rsWrapper #darkGrey2:hover .hoverIcon {
    opacity: 1;
  }

  /* article .rsWrapper #darkGrey2 .hoverIcon {
    opacity: 0;
    position: absolute;
    left: 41px;
  } */

  article .rsWrapper .reviewCreate .question #darkGrey2 {
    width: 100%;
    height: 48px;
    border-radius: 50px;
    position: relative;
  }
  article .rsWrapper .reviewCreate .question {
    max-width: 100%;
  }
  /* article .rsWrapper #darkGrey2 img {
    width: 25px;
    margin: 0 10px 0 0;
  } */

  article .rsWrapper .reviewCreate .question > p {
    font-size: 14px;
  }

  article .rsWrapper .reviewBox {
    justify-content: space-between;
    margin: 0 0 10px;
  }

  article .rsWrapper .reviewBox h1 {
    font-size: 20px;
  }

  article .rsWrapper .reviewCreate .create {
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }

  article .rsWrapper .reviewCreate .create div {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  #detail .rsWrapper .reviewCreate {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  article .rsWrapper .reviewCreate .review {
    margin: 0 0 10px;
  }

  article .rsWrapper .reviewCreate .create div .mobileInput:focus {
    border: 1px solid #b041f3;
    background: #ffffff;
  }

  article .rsWrapper .reviewCreate .create div .mobileInput {
    display: initial;
    width: 100%;
    height: 130px;
    border-radius: 4px;
    background: #f4f5f7;
    padding: 16px 12px;
    background: #f4f5f7;
    /* border: none; */
    box-shadow: none;
    margin: 0 0 10px;
  }

  article .rsWrapper .reviewCreate .create div .mobileInput::placeholder {
    color: #a3a1a7;
  }

  #detail .reviewList {
    margin-top: 20px;
  }

  #detail .rsWrapper .reviewList .list .userReview > div {
    width: 100%;
    padding: 4px 0 8px;
    gap: 8px;
  }

  article .rsWrapper .reviewList .list .userReview > div .content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
  #detail .rsWrapper .reviewList .list {
    width: 100%;
    border-radius: 20px;
  }

  article .rsWrapper .reviewList .list .answer {
    border-radius: 30px 0 0 0;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
  }

  article .rsWrapper .reviewList .list .answer .mobileInput {
    display: initial;
    width: 100%;
    height: 130px;
    background: #f4f5f7;
    padding: 16px 12px;
    font-size: 13px;
    border: none;
    box-shadow: none;
    margin: 0 0 10px;
  }

  article .rsWrapper .reviewList .list .userReview > div .top div img,
  article .rsWrapper .reviewList .list .userReview > div .top div .mobileRate,
  article .rsWrapper .reviewList .list .userReview > div .top > p {
    display: initial;
  }

  article .rsWrapper .reviewList .list .userReview > div .top div img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0 10px 0 0;
  }

  article .rsWrapper .reviewList .list .userReview > div .top div p {
    font-size: 14px;
    margin: 0 8px 0 0;
    border-bottom: 1px solid #0e0e1f;
  }

  article .rsWrapper .reviewList .list .userReview > div .top > p {
    font-size: 12px;
    color: #6d6c75;
  }

  #detail .rsWrapper .reviewList .list .userReview > div .top #darkGreyBorder {
    display: none;
  }

  #detail .rsWrapper .reviewList .list .userReview > div #darkGreyBorder.mobileBtn {
    display: flex;
    align-self: end;
    width: 45px;
    height: 30px;
    border-radius: 24px;
  }

  article .rsWrapper .reviewList .list .userReview {
    margin: 0;
  }

  article .rsWrapper .reviewList .list .mobileBtn {
    display: flex;
  }

  article .rsWrapper .reviewList .list .mobileBtn #greyBtn {
    width: 45px;
    height: 30px;
    font-size: 12px;
    border-radius: 24px;
    margin: 0 0 10px;
  }

  article .rsWrapper .allReview {
    align-items: flex-end;
  }
  article .top .rsWrapper .left .box16 {
    height: 397px;
  }

  article .top .rsWrapper .left .imgScroll {
    height: 397px;
  }

  article .top .rsWrapper .right .tip div h1,
  article .top .rsWrapper .right .tip div p {
    font-size: 12px;
  }

  article#detail .top .rsWrapper .right .orderFirst button {
    display: inline-block;
  }

  .imageModal .pagination h1 {
    display: none;
  }

  .imageModal .pagination {
    padding: 0 16px;
  }

  .imageModal div > h1 {
    position: absolute;
    top: 200px;
    right: 16px;
    color: #ffffff;
    font-size: 14px;
    z-index: 10;
  }

  #reviewModal .reviewTitle {
    border-bottom: none;
  }

  #reviewModal .reviewBox {
    flex-wrap: wrap;
  }

  #reviewModal .reviewBox h1 {
    font-size: 26px;
    font-weight: bold;
    color: #323146;
    margin: 0 0 5px;
  }

  #reviewModal .reviewBox h2 {
    width: 100%;
    margin: 0 0 10px;
    font-size: 20px;
  }

  #reviewModal .listBox .listScroll .list .userReview > div {
    width: 100%;
    padding: 14px 10px;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }

  #reviewModal .listBox .listScroll .list {
    width: 100%;
    border-radius: 20px;
  }

  #reviewModal .listBox .listScroll .list .answer {
    border-radius: 30px 0 0 0;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
  }

  #reviewModal .listBox .listScroll .list .answer .mobileInput {
    display: initial;
    width: 100%;
    height: 130px;
    background: #f4f5f7;
    padding: 16px 12px;
    font-size: 13px;
    border: none;
    box-shadow: none;
    margin: 0 0 10px;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .top div {
    width: auto;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .top div img,
  #reviewModal .listBox .listScroll .list .userReview > div .top div .mobileRate,
  #reviewModal .listBox .listScroll .list .userReview > div .top > p {
    display: initial;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .top div img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 0 10px 0 0;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .top div p {
    font-size: 14px;
    margin: 0 8px 0 0;
    border-bottom: 1px solid #0e0e1f;
  }

  #reviewModal .listBox .listScroll .list .userReview > div .top > p {
    font-size: 12px;
    color: #6d6c75;
  }

  #reviewModal .listBox .listScroll .list .userReview {
    margin: 0;
  }

  #reviewModal .listBox .listScroll .list .mobileBtn {
    display: flex;
  }

  #reviewModal .listBox .listScroll .list .mobileBtn #greyBtn {
    width: 45px;
    height: 30px;
    font-size: 12px;
    border-radius: 24px;
    margin: 0 0 10px;
  }

  #reviewModal .listBox {
    height: auto;
    overflow: hidden;
  }

  article .top .rsWrapper .left .user > img {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  article > .top {
    padding: 28px 0 8px;
  }
  /*댓글 */
  #detail .rsWrapper .reviewList .list.answer {
    width: 100%;
    padding: 12px;
    border-radius: 20px;
    background: rgba(62, 61, 93, 0.1);
    align-items: flex-end;
    margin: 0 0 12px;
    flex-direction: column;
  }
  #detail .rsWrapper .reviewList .list .answer {
    width: 100%;
    align-items: flex-start;
    border-radius: 30px 0 0 0;
    padding: 12px 12px;
  }

  #detail .rsWrapper .reviewList .list.answer .userReview {
    width: 100%;
    align-items: flex-start;
    padding: 0px;
    border-radius: 30px 0 0 0;
  }
  #detail .rsWrapper .reviewList .list .answer > div > div h1 {
    font-size: 14px;
  }
  #detail .promptThumList.forceWidth {
    gap: 0;
    padding-bottom: 0;
  }
  .slick-slider {
    margin: 0 !important;
  }
  #listWrapper #otherWrapper #populerPromptSlider {
    width: 1518px;
    padding: 20px 16px 0;
  }

  #otherWrapper .rsWrapper .newContents .staffList .staffBox {
    margin: 0 !important;
  }
  #otherWrapper .rsWrapper .staff .staffList,
  #otherWrapper .rsWrapper .newContents .staffList {
    justify-content: space-between;
  }

  #otherWrapper .rsWrapper .staff .staffList .staffBox,
  #otherWrapper .rsWrapper .newContents .staffList .staffBox {
    width: 50% !important;
  }
}
