/* 프로필 영역 */
#artistProfile #darkGrey {
    border: solid 1px #525162;
    background: #525162;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 12px;
}

#myProfile .profileBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 62.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .profileBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 42.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .profileBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 48.5%;
    right: 22.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile #profileImage {
    border-radius: 8px;
    transition: filter 0.3s ease-in-out;
}

#myProfile .profilePreview {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

#myProfile .plusBannerBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 43%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -43%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .plusBannerBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .plusBannerBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 57%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -57%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#myProfile .introView {
    border-radius: 2%;
    transition: filter 0.3s ease-in-out;
}

#myProfile .introPreview {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

/* 아티스트 영역 */
#artistProfile .plusBox {
    width: 140px;
    height: 140px;
    border-radius: 8px;
    border: 1px dashed #e21dfd;
    background: #fcf7fd;
    cursor: pointer;
    overflow: hidden;
}

#artistProfile .ant-upload-drag-container .plusBox .imageContainer {
    width: 100%;
}

#artistProfile .plusBox .plus {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #f0dbf4;
    color: #c49bcc;
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 4px;
}

#artistProfile .plusBox p {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #c49bcc;
    line-height: 1.2;
}

#artistProfile .profileUpload img {
    width: 140px;
    height: 140px;
}

#artistProfile .profileBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 62.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistProfile .profileBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 42.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistProfile .profileBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 48.5%;
    right: 22.5%;
    width: 20px !important;
    height: 20px !important;
    transform: translateY(-50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistProfile #profileImage {
    border-radius: 8px;
    transition: filter 0.3s ease-in-out;
}

#artistProfile .profilePreview {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

#artistBanner .plusBannerBox {
    min-width: 760px;
    border: 1px dashed #e21dfd;
    background: #fcf7fd;
    min-height: 219px;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
}

#artistBanner .plusBannerBox .ant-upload-drag-container {
    width: 100%;
    height: 100%;
}

#artistBanner .plusBannerBox .plus {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #f0dbf4;
    color: #c49bcc;
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 4px;
}

#artistBanner .plusBannerBox p {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #c49bcc;
    line-height: 1.2;
}

#artistBanner .plusBannerBox .iconExpand {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 43%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -43%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistBanner .plusBannerBox .iconChange {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -50%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistBanner .plusBannerBox .iconTrash {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 57%;
    width: 20px !important;
    height: 20px !important;
    transform: translate(-50%, -57%);
    pointer-events: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

#artistBanner .introView {
    border-radius: 2%;
    transition: filter 0.3s ease-in-out;
}

#artistBanner .introPreview {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

